import { Signal } from '@angular/core';
import { NavItemDataProperty } from '../NavItemDataProperty';
import { NavItemType } from '../NavItemType';
import { NavItem, NavItemData, isNavItem } from '../base';

export interface ExternalLinkNavItemData extends NavItemData {
  type: NavItemType.ExternalLink;
  url: Signal<string>;
  target: Signal<string | undefined>;
}

export interface ExternalLinkNavItem extends NavItem {
  type: NavItemType.ExternalLink;
  url: NavItemDataProperty<string>;
  target?: NavItemDataProperty<string>;
}

export function isExternalLinkNavItem(item: NavItem): item is ExternalLinkNavItem {
  return isNavItem(item) && item.type === NavItemType.ExternalLink;
}
