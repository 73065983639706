import { NavItemType } from '../NavItemType';
import { NavItem, isNavItem } from '../base/NavItem';
import { RouteNavItem, RouteNavItemData } from '../route/RouteNavItem';

export interface RouteButtonNavItemData extends RouteNavItemData {
  type: NavItemType.RouteButton;
}
export interface RouteButtonNavItem extends RouteNavItem {
  type: NavItemType.RouteButton;
}

export function isRouteButtonNavItem(item: NavItem): item is RouteButtonNavItem {
  return isNavItem(item) && item.type === NavItemType.RouteButton;
}
