import { Signal } from '@angular/core';
import { NavItem, NavItemData, isNavItem } from '../base/NavItem';
import { NavItemDataProperty } from '../NavItemDataProperty';
import { NavItemType } from '../NavItemType';

export interface ButtonNavItemData extends NavItemData {
  type: NavItemType.Button;
  onClick: (event: Event) => void;
  active: Signal<boolean>;
}

export interface ButtonNavItem extends NavItem {
  type: NavItemType.Button;
  onClick: () => (event: Event) => void;
  active?: NavItemDataProperty<boolean>;
}

export function isButtonNavItem(item: NavItem): item is ButtonNavItem {
  return isNavItem(item) && item.type === NavItemType.Button;
}
