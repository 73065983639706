import { Signal } from '@angular/core';
import { NavItemDataProperty } from '../NavItemDataProperty';
import { NavItemType } from '../NavItemType';
import { NavItem, isNavItem } from '../base/NavItem';
import { RouteNavItem, RouteNavItemData } from '../route/RouteNavItem';

export interface RouteLinkNavItemData extends RouteNavItemData {
  type: NavItemType.RouteLink;
  target: Signal<string | undefined>;
}

export interface RouteLinkNavItem extends RouteNavItem {
  type: NavItemType.RouteLink;
  target?: NavItemDataProperty<string>;
}

export function isRouteLinkNavItem(item: NavItem): item is RouteLinkNavItem {
  return isNavItem(item) && item.type === NavItemType.RouteLink;
}
