import { Signal } from '@angular/core';
import { IsActiveMatchOptions, NavigationExtras } from '@angular/router';
import { NavItem, NavItemData, isNavItem } from '../base/NavItem';
import { NavItemDataProperty } from '../NavItemDataProperty';
import { NavItemType } from '../NavItemType';

export interface RouteNavItemData extends NavItemData {
  type: NavItemType.RouteLink | NavItemType.RouteButton;
  command: Signal<any[]>;
  args: Signal<NavigationExtras | undefined>;
  linkOptions: Signal<IsActiveMatchOptions | { exact: boolean } | undefined>;
}

export interface RouteNavItem extends NavItem {
  type: NavItemType.RouteLink | NavItemType.RouteButton;
  command: NavItemDataProperty<any[]>;
  args?: NavItemDataProperty<NavigationExtras>;
  linkOptions?: NavItemDataProperty<IsActiveMatchOptions | { exact: boolean }>;
}

export function isRouteNavItem(item: NavItem): item is RouteNavItem {
  return isNavItem(item) && (item.type === NavItemType.RouteLink || item.type === NavItemType.RouteButton);
}
// interface IsActiveMatchOptions {
//   matrixParams: "exact" | "subset" | "ignored";
//   queryParams: "exact" | "subset" | "ignored";
//   paths: "exact" | "subset";
//   fragment: "exact" | "ignored";
// }
